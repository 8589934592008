<template>
  <loader :is-loading="offersLoading" color="primary" :margin="0">
    <v-col class="offers-col">
      <v-row class="form-header without-padding">
        <h5 class="section-title">{{ $t("book.form.offer.label") }}</h5>
      </v-row>
      <v-row>
        <v-select
          color="default"
          class="offer-selector show-border"
          :items="offers"
          append-icon="mdi-chevron-down"
          :placeholder="$t('book.form.selectVehicle')"
          :menu-props="{ top: true, offsetY: true }"
          item-value="id"
          attach
          text
          light
          flat
          solo
          return-object
          hide-details="auto"
          v-model="offerSelected"
          @change="selectOffer"
        >
          <template slot="prepend-inner">
            <icabbi-car />
          </template>
          <template slot="selection" slot-scope="{ item }">
            {{ item.name | fallbackToString("book.form.offer.name") }}
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title>
                  <v-flex>
                    <p class="mb-0">{{ item.name }}</p>
                  </v-flex>
                </v-list-item-title>
              </v-list-item-content>
            </v-list>
          </template>
        </v-select>
      </v-row>
    </v-col>
  </loader>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { iconLoader } from '../../../../helpers/iconsLoader';

export default {
  name: 'offer-picker',
  components: {
    ...iconLoader.booking,
  },
  props: {
    offer: Object,
    isEditMode: Boolean,
    pickupTime: Number,
    paymentMethod: Object,
    waypoints: Array,
  },
  data() {
    return {
      screenName: 'booking',
      offerSelected: null,
      fleetSelected: null,
      mappedOffers: {},
      availableOffers: [],
    };
  },
  watch: {
    getPickupAndDestinationCoordinates: {
      handler(val) {
        if (!val) return;

        this.callFetchOffers();
      },
    },
    pickupTime: {
      handler(val) {
        if (!val) return;
        this.callFetchOffers();
      },
    },
    paymentMethod: {
      handler(val) {
        if (!val) return;
        this.callFetchOffers();
      },
    },
    waypoints: {
      handler(val) {
        if (!val || (val.length === 1 && !val[0].id)) return;
        this.callFetchOffers();
      },
    },
  },
  computed: {
    ...mapGetters({
      offers: 'offers/offers',
      offersLoading: 'offers/offersLoading',
      pickup: 'geolocation/pickup',
      destination: 'geolocation/destination',
    }),
    getPickupAndDestinationCoordinates() {
      const destinationCoordinates = _.get(this.destination, 'coordinates');
      const pickupCoordinates = _.get(this.pickup, 'coordinates');

      if (destinationCoordinates && pickupCoordinates) {
        return {
          destinationLatitude: destinationCoordinates.latitude,
          destinationLongitude: destinationCoordinates.longitude,
          pickupLatitude: pickupCoordinates.latitude,
          pickupLongitude: pickupCoordinates.longitude,
        };
      }

      return null;
    },
  },
  methods: {
    offerChanged() {
      this.selectOffer();
    },
    selectOffer(val) {
      this.$emit('input', { offerId: val.id, fleetId: val.fleetId });
    },
    async callFetchOffers() {

      const options = {
        ...this.getPickupAndDestinationCoordinates,
        lang: this.$i18n.locale,
      };

      const isPickupLocationSet = options.pickupLatitude && options.pickupLongitude;
      const isDestinationLocationSet = options.destinationLatitude && options.destinationLongitude;

      if (!isPickupLocationSet || !isDestinationLocationSet) {
        // eslint-disable-next-line no-console
        console.log('Pickup or destination location is not set. Skipping fetching offers');
        return;
      }

      if (this.paymentMethod.businessAccount && this.paymentMethod.businessAccount.id) {
        options.corporationId = this.paymentMethod.businessAccount.id;
      }
      try {
        await this.$store.dispatch('offers/getOffersList', options);

        if (this.offers.length > 0) {
          this.offerSelected = this.offer ? this.offers.find(el => el.oldOfferId === this.offer.id) : this.offers[0];
        }

        this.$emit('input', { offerId: this.offerSelected.id, fleetId: this.offerSelected.fleetId });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching offers', error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-header {
  &.with-padding-large {
    padding-top: 23px;
  }
  &.with-padding-medium {
    padding-top: 15px;
  }
  &.without-padding {
    padding-top: 0;
  }
}

.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 8px !important;
}
</style>

<style lang="scss">
.offer-type {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 73px;
  height: 59px;
  position: relative;
  margin-bottom: 10px;
}
.offer-type:hover,
.offer-type--selected {
  cursor: pointer;
  border: 2px solid #148eff;
}

.offer-selector {
  padding: 10px;

  .v-list {
    padding: 0px;
  }

  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          margin-left: 5px;
          margin-bottom: -5px !important;
        }
      }
    }
  }
}

.section-title {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 10px;
  font-weight: 400;
  color: #888;
  padding-bottom: 10px;
}

.offers-col {
  padding: 12px 12px 0 12px;
}

@media only screen and (max-width: 960px) {
  .offer-type {
    width: 60px;
  }
}
</style>
