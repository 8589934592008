<template>
  <div
    class="booking-menu-container"
    :class="{ 'max-height-no-fare': !fareEstimation, 'max-height-fare': fareEstimation }"
  >
    <v-card class="mx-md-auto booking-card px-4 px-md-8">
      <v-card-title class="book-menu-title py-6 px-0">
        <loader
          :margin="0"
          :justifyCenter="false"
          :size="30"
          :is-loading="userLoading"
          color="primary"
        >
          {{ $t("book.form.title") }}
        </loader>
      </v-card-title>

      <v-card-text v-show="!showAccountFields" class="text--primary px-0 pb-1">
        <template v-if="isPreBookingEnabled">
          <validated-component
            name="pickupTime"
            :classes="['time-input-error']"
            v-model="pickupTime"
            :validator="$v.booking.pickupTime"
            hideDetails="auto"
          >
            <pickup-time
              class="mb-4"
              :pickupTime="selectedPickupTime"
              v-on:pickupTimeUpdated="updatePickupTime"
            ></pickup-time>
          </validated-component>
        </template>
        <h5 class="section-title pb-0">
          {{
            isShowFlightNumber
              ? $t("book.form.sectionMultipleAdressAirport")
              : $t("book.form.sectionMultipleAdress")
          }}
        </h5>

        <address-picker
          class="mb-4"
          ref="multipleAddress"
          :pickup="pickup"
          :destination="destination"
          :waypoints="waypoints"
          :validator="$v.booking"
          @updateAddresses="updateAddresses"
          @addWaypoint="addWaypoint"
          @removeWaypoint="removeWaypoint"
          @updateFlightNumber="flightNumber = $event"
          @setFlightNumber="isShowFlightNumber = $event"
        />

        <div class="form-header with-padding-medium pt-0">
          <h5 class="section-title">{{ $t("book.form.payment.label") }}</h5>
        </div>
        <loader :is-loading="offersLoading" color="primary" :margin="0">
          <v-select
            hide-details="auto"
            class="show-border payment-select"
            v-model="paymentMethod"
            :placeholder="$t('book.form.payment.placeholder')"
            :items="strategy.paymentMethodItems"
            append-icon="mdi-chevron-down"
            color="default"
            item-value="id"
            :menu-props="{ nudgeBottom: 50 }"
            return-object
            attach
            text
            flat
            light
            solo
          >
            <template v-slot:selection="{ item }">
              <component
                v-if="item.paymentMethodType !== 'cash'"
                v-bind:is="creditCardIcon(item.businessAccount ? 'business' : item.brand)"
                class="mr-2"
              />
              <component v-else v-bind:is="creditCardIcon(item.paymentMethodType)" class="mr-2" />
              {{ paymentMethodName(item) }}
            </template>

            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                :disabled="checkBusinessDispatchInvoiceDisabled(item.paymentMethodType)"
                :class="{
                  'payment-disabled': checkBusinessDispatchInvoiceDisabled(item.paymentMethodType)
                }"
                v-on="on"
                v-bind="attrs"
              >
                <v-list-item-icon v-if="item.paymentMethodType !== 'cash'">
                  <component
                    v-bind:is="creditCardIcon(item.businessAccount ? 'business' : item.brand)"
                  />
                </v-list-item-icon>
                <v-list-item-icon v-else>
                  <component v-bind:is="creditCardIcon(item.paymentMethodType)" class="mr-2" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ paymentMethodName(item) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:prepend-item v-if="isCreditCardPaymentMethodAllowed">
              <v-list-item title="Create new payment method" @click="$emit('addCard')">
                <v-list-item-icon>
                  <component v-bind:is="creditCardIcon('new-card')" />
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t("book.form.payment.new") }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </loader>

        <v-divider class="my-6"></v-divider>

        <offer-picker
          class="mb-4"
          :offer="offer"
          :isEditMode="isEditMode"
          :pickupTime="pickupTime"
          :paymentMethod="paymentMethod"
          :waypoints="waypoints"
          @input="updateOffer"
        ></offer-picker>

        <validated-component
          name="notes"
          v-model="notes"
          :validator="$v.booking.notes"
          hideDetails="auto"
        >
          <v-textarea
            :hide-details="true"
            color="primary"
            class="show-border mt-4 textarea-field"
            flat
            light
            solo
            height="100px"
            no-resize
            full-width
            v-model="notes"
            required
          ></v-textarea>
        </validated-component>
      </v-card-text>
      <v-card-text v-show="showAccountFields" class="px-0">
        <v-row v-for="(field, i) in accountFields" :key="field.id" class="px-0">
          <v-col>
            <h5 class="section-title pb-2">{{ field.title }}</h5>
            <v-select
              v-if="field.type === 'select'"
              outlined
              filled
              single-line
              hide-no-data
              hide-details
              :rules="[...field.validation]"
              :placeholder="field.description"
              v-model="field.value"
              :items="field.values"
            />
            <v-text-field
              v-else
              outlined
              filled
              single-line
              :rules="[...field.validation]"
              clearable
              hide-details
              v-model="field.value"
              :placeholder="field.title"
            >
            </v-text-field>
            <v-divider class="my-6 mb-0" v-if="i + 1 < accountFields.length" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="!showAccountFields" class="px-0 d-flex align-center">
        <v-checkbox
          v-model="bookReturnBooking"
          :ripple="false"
          dense
          hide-details
          class="mt-0 pt-0"
        ></v-checkbox>
        <span>{{ $t("book.form.returnTrip") }}</span>
      </v-card-text>
      <div class="d-md-none d-flex justify-space-between pb-4">
        <span class="cancel-button" @click="cancelEdit">{{
          isEditMode ? $t(`book.form.cancel-edit`) : $t(`book.form.cancel`)
        }}</span>
        <v-btn
          v-if="strategy.showConfirmation && (fieldsRequired || employeeGroupsSelectionRequired)"
          block
          depressed
          color="primary"
          v-on:click="showConfirmation()"
          class="button-book-now"
          >{{ $t("book.form.submit.value") }}
        </v-btn>
        <v-btn v-else depressed color="primary" v-on:click="submitBooking()" class="button-book-now"
          >{{ isEditMode ? $t("book.form.submit.update") : $t("book.form.submit.value") }}
        </v-btn>
      </div>
    </v-card>
    <div
      v-if="!offersLoading && fareEstimation && !showAccountFields && getFormattedFare !== 0"
      class="d-flex fare-estimate-field pb-3 mb-md-0 px-4 px-md-8 pt-3"
    >
      <p class="mb-0">
        {{ `${$t("fare")} (${$t(fareEstimation.type)})` }}
      </p>
      <v-spacer></v-spacer>
      <p class="mb-0">{{ `${currencySymbol}` }} {{ getFormattedFare }}</p>
    </div>
    <div
      v-if="!offersLoading"
      class="d-none d-md-flex booking-fixed-controls justify-space-between px-4 px-md-8"
      :class="{ 'pt-10 pt-md-6': !fareEstimation, 'pt-3': fareEstimation }"
    >
      <span class="cancel-button" @click="cancelEdit">{{
        isEditMode ? $t(`book.form.cancel-edit`) : $t(`book.form.cancel`)
      }}</span>
      <v-btn
        v-if="strategy.showConfirmation && (fieldsRequired || employeeGroupsSelectionRequired)"
        block
        depressed
        color="primary"
        v-on:click="showConfirmation()"
        class="button-book-now"
        >{{ $t("book.form.submit.value") }}
      </v-btn>
      <v-btn v-else depressed color="primary" v-on:click="submitBooking()" class="button-book-now"
        >{{ isEditMode ? $t("book.form.submit.update") : $t("book.form.submit.value") }}
      </v-btn>
    </div>

    <v-dialog v-model="travelRestrictionError" persistent max-width="550">
      <v-card>
        <v-card-title class="headline">{{
          $t(`travelRestrictionErrors.${travelRestrictionErrorType}.title`)
        }}</v-card-title>
        <v-card-text>
          <p class="description-travel-restriction">
            {{ $t(`travelRestrictionErrors.${travelRestrictionErrorType}.description`) }}
          </p>
          <p>{{ $t("travelRestrictionErrors.contact") }}</p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary" text @click="confirmErrorDialog()">{{
            $t(`travelRestrictionErrors.confirm`)
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDuplicate" persistent max-width="400">
      <v-card class="pb-2">
        <v-card-title class="headline">{{ $t("book.form.duplicate.dialog.title") }}</v-card-title>
        <v-card-text>
          <p class="mb-0">{{ $t("book.form.duplicate.dialog.text") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="disable-button"
            color="grey darken-1"
            text
            @click="closeConfirmDuplicateDialog"
          >
            {{ $t("book.form.duplicate.dialog.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="confirmDuplicateBooking">{{
            $t("book.form.duplicate.dialog.confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {
  isBeforeLimitTimeValues,
  isNotInThePast,
  isAfterPickupTime,
} from '@/validation/validationMethods';
import dayjs from '@/daysjs';

import { creditCardIcon } from '@/mixins/creditCardIcons.mixins';
import OfferPicker from '@/views/shared/inputs/offers/OfferPicker.vue';
import PickupTime from '@/views/shared/inputs/PickupTime.vue';
import Ic2AccountConfirmationDialog from '@/views/book/bookingMenu/ic2Account/Ic2AccountConfirmationDialog.vue';
import BusinessAccountConfirmationDialog from '@/views/book/bookingMenu/businessAccount/BusinessAccountConfirmationDialog.vue';

import bookingValidation from "@/validation/bookingValidationData"; // eslint-disable-line

import { NumberFormatter } from '@icabbi/hi-dash';
import AddressPicker from './AddressPicker.vue';
import { paymentMethods } from '../../mixins/paymentMethod.mixins';
import UserMixin from '../shared/mixins/UserMixin';

import strategyHelper from './strategyHelpers';
import ConfirmDialog from '../../components/elements/ConfirmDialog.vue';
import DialogWrapper from '../../components/elements/DialogWrapper.vue';

export default {
  name: 'booking-menu',
  props: {
    loadedBooking: Object,
    state: String,
    newPaymentMethodId: {
      type: String,
    },
    returnBookingTime: {
      type: String,
    },
    returnFlightNumber: {
      type: String || null,
    },
    returnBookingCheckbox: {
      type: Boolean,
    },
  },
  components: {
    AddressPicker,
    OfferPicker,
    PickupTime,
    Ic2AccountConfirmationDialog,
    BusinessAccountConfirmationDialog,
    ConfirmDialog,
    DialogWrapper,
  },
  mixins: [UserMixin, creditCardIcon, paymentMethods],
  validations() {
    return {
      booking: {
        ...bookingValidation.booking,
        pickupTime: {
          isNotInThePast,
          isBeforeLimitTimeValues: this.isPaymentMethodTypeCash,
        },
        returnTime: {
          isNotInThePast,
          isBeforeLimitTimeValues: this.isPaymentMethodTypeCash,
          isAfterPickupTime,
        },
      },
    };
  },
  data() {
    return {
      confirmationDialog: false,
      confirmationFields: {},
      screenName: 'booking',
      showAccountFields: false,
      bookReturnBooking: false,
      isShowFlightNumber: false,
      pickup: {
        description: '',
        coordinates: {},
      },
      destination: {
        description: '',
        coordinates: {},
      },
      offerId: null,
      fleetId: null,
      rules: null,
      flightNumber: null,
      isFareLoading: false,
      fareEstimation: null,
      userInformation: {},
      notes: null,
      validationData: bookingValidation,
      waypoints: [],
      pickupTime: null,
      selectedPickupTime: null,
      paymentMethod: {
        id: 'cash',
        paymentMethodType: 'cash',
      },
      strategy: strategyHelper.base(),
      employeeGroup: null,
      offer: null,
      passenger: null,
      validationFields: null,
    };
  },
  async created() {
    if (!this.user) {
      await this.$store.dispatch('userProfile/getUser');
    } else {
      this.updateUserInformation(this.user);
    }

    await this.loadPaymentMethods();
    await this.loadBookingsPaymentRequiresAction();
  },
  watch: {
    bookReturnBooking(val) {
      if (val) {
        this.$emit('bookReturnBooking', {
          pickupTime: this.pickupTime,
          destination: this.destination,
        });
      }
    },
    returnBookingCheckbox: {
      immediate: true,
      handler(val) {
        this.bookReturnBooking = val;
      },
    },
    async newPaymentMethodId(val) {
      await this.loadPaymentMethods();
      this.paymentMethod = this.strategy.paymentMethodItems.filter(item => item.id === val)[0];
    },
    pickupTime() {
      this.getFareEstimation();
    },
    user(val) {
      this.updateUserInformation(val);
    },
    destination() {
      this.getFareEstimation();
    },
    waypoints(val) {
      this.booking.waypoints = val;

      this.getFareEstimation();
    },
    origin() {
      this.getFareEstimation();
    },
    fleetId() {
      this.getFareEstimation();
    },
    offerId() {
      this.getFareEstimation();
    },
    async strategy() {
      await this.getPaymentMethods();

      this.strategy.paymentMethodItems = this.formatPaymentMethodsTableList;
    },
    loadedBooking(bkg) {
      if (bkg) {
        if (bkg.pickupTime) {
          const pickupTime = dayjs(bkg.pickupTime).isBefore(dayjs())
            ? null
            : dayjs(bkg.pickupTime).format('YYYY-MM-DDTHH:mm');

          this.selectedPickupTime = pickupTime;
        }

        if (bkg.origin) {
          this.pickup = {
            description: bkg.origin.address,
            coordinates: {
              latitude: bkg.origin.latitude,
              longitude: bkg.origin.longitude,
            },
            locationTypes: bkg.origin.locationTypes,
            dispatchAddressId: bkg.origin.dispatchAddressId || undefined,
          };

          this.$store.commit('geolocation/setSuggestionDetails', {
            address: this.pickup,
            type: 'pickup',
            index: 0,
          });
        }

        if (bkg.destination) {
          this.destination = {
            description: bkg.destination.address,
            coordinates: {
              latitude: bkg.destination.latitude,
              longitude: bkg.destination.longitude,
            },
            locationTypes: bkg.destination.locationTypes,
            dispatchAddressId: bkg.destination.dispatchAddressId || undefined,
          };

          this.$store.commit('geolocation/setSuggestionDetails', {
            address: this.destination,
            type: 'destination',
            index: 0,
          });
        }

        if (bkg.waypoints.length > 0) {
          this.waypoints = bkg.waypoints.map(el => ({
            description: el.address,
            coordinates: {
              latitude: el.latitude,
              longitude: el.longitude,
            },
          }));

          this.waypoints.forEach((el, index) => {
            this.$store.commit('geolocation/setSuggestionDetails', {
              address: el,
              type: 'waypoints',
              index,
            });
          });
        }

        if (bkg.notes) {
          this.notes = bkg.notes;
        }

        if (bkg.fields) {
          this.validationFields = bkg.fields;
        }

        if (bkg.notes) {
          this.notes = bkg.notes;
        }

        if (bkg.fleetId) {
          this.fleetId = bkg.fleetId;
        }

        if (bkg.offer) {
          this.offerId = bkg.offer.id;
          this.offer = bkg.offer;
        }

        if (bkg.fields) {
          this.validationFields = bkg.fields;
        }

        this.passenger = {
          id: bkg.userId,
          corporationId: bkg.corporationId,
          bookingChannelId: bkg.bookingChannelId,
          fullName: `${bkg.userInfo.givenName} ${bkg.userInfo.familyName}`,
          phone: bkg.userInfo.phone,
          type: bkg.passengerAccountType === 'corporationEmployee' ? 'employee' : 'guest',
        };
      }
    },
    async paymentMethod(val) {
      if (val.ownerType === 'business') {
        await this.$store.dispatch('book/getAccountFields', {
          fleetId: this.fleetId,
          businessAccountId: val.businessAccount.id,
        });
      }

      if (this.validationFields) {
        Object.keys(this.validationFields).forEach((field, index) => {
          this.accountFields[index].value = this.validationFields[field];
        });
      }

      this.getFareEstimation();
    },
    bookingsRequirePaymentAction() {
      if (this.bookingsRequirePaymentAction.length > 0) {
        this.$emit('bookingPaymentActionRequired', this.bookingsRequirePaymentAction);
      }
    },
  },
  computed: {
    ...mapGetters({
      accountFields: 'book/getAccountFields',
      employeeGroups: 'employeeGroups/employeeGroups',
      user: 'userProfile/user',
      userLoading: 'userProfile/userLoading',
      travelRestrictionError: 'book/getTravelRestrictionError',
      bookingCreated: 'book/getBookingCreated',
      bookingInCreation: 'book/getBookingInCreation',
      pickupGeocodingInProgress: 'geolocation/pickupGeocodingInProgress',
      bookingChannelId: 'bookingChannel/bookingChannelId',
      bookingChannelFeatures: 'bookingChannel/features',
      allowedPaymentMethods: 'bookingChannel/allowedPaymentMethods',
      publicKeyCredential: 'bookingChannel/payment',
      paymentProviderName: 'paymentMethods/paymentProviderName',
      currencySymbol: 'bookingChannel/currencySymbol',
      offersLoading: 'offers/offersLoading',
      confirmDuplicate: 'book/getDuplicateConfirmError',
      bookingDuplicateInfos: 'book/getBookingDuplicateInfos',
      bookingTab: 'bookings/bookingTab',
      defaultLocale: 'bookingChannel/defaultLocale',
      bookingsRequirePaymentAction: 'bookings/bookingsRequirePaymentAction',
    }),
    isPaymentMethodTypeCash() {
      return this.paymentMethod.paymentMethodType !== 'cash'
        ? isBeforeLimitTimeValues(30, 'days')
        : true;
    },
    isEditMode() {
      return this.state === 'edit';
    },
    fieldsRequired() {
      return this.accountFields && this.accountFields.length > 0;
    },
    employeeGroupsSelectionRequired() {
      return this.employeeGroups && this.employeeGroups.length > 1;
    },
    getFormattedFare() {
      if (!this.fareEstimation) return false;
      if (this.fareEstimation.type === 'RANGE') {
        const minFare = NumberFormatter.stringValueDecimalSeparator(
          this.fareEstimation.minimumFare.toFixed(2),
          this.defaultLocale
        );
        const maxFare = NumberFormatter.stringValueDecimalSeparator(
          this.fareEstimation.maximumFare.toFixed(2),
          this.defaultLocale
        );
        return `${minFare} - ${maxFare}`;
      }

      if (this.fareEstimation.roundedPrice) {
        return NumberFormatter.stringValueDecimalSeparator(
          this.fareEstimation.roundedPrice.toFixed(2),
          this.defaultLocale
        );
      }

      return 0;
    },
    disablePostBooking() {
      return this.$v.$invalid || this.bookingInCreation;
    },
    // eslint-disable-next-line max-lines-per-function
    booking() {
      let passengerInfo = null;
      if (this.passenger) {
        passengerInfo = {
          passengerId: this.passenger.id,
          passengerType: this.passenger.type,
          corporationId: this.passenger.corporationId,
        };
      }

      const booking = {
        offerId: this.offerId,
        pickupTime: this.pickupTime ? dayjs(this.pickupTime).format('YYYY-MM-DDTHH:mm:ss') : null,
        origin: {
          address: this.pickup.description,
          latitude: this.pickup.coordinates
            ? this.pickup.coordinates.latitude
            : this.pickup.latitude,
          longitude: this.pickup.coordinates
            ? this.pickup.coordinates.longitude
            : this.pickup.longitude,
          dispatchAddressId: this.pickup.dispatchAddressId || undefined,
          locationTypes: this.pickup.locationTypes,
        },
        destination: {
          address: this.destination.description,
          latitude: this.destination.coordinates
            ? this.destination.coordinates.latitude
            : this.destination.latitude,
          longitude: this.destination.coordinates
            ? this.destination.coordinates.longitude
            : this.destination.longitude,
          dispatchAddressId: this.destination.dispatchAddressId || undefined,
          locationTypes: this.destination.locationTypes,
        },
        waypoints: this.waypoints,
        userInfo: _.omitBy(this.userInformation, _.isEmpty),
        // for business booking
        passengerInfo,
        notes: this.notes === '' ? null : this.notes,
        paymentMethod: this.getPaymentObject(this.paymentMethod),
      };

      booking.bookingProperties = {
        flightInfo: {
          number: this.flightNumber,
          isMandatory:
            this.isShowFlightNumber
            && this.bookingChannelFeatures.airportPickupSettings
            && !this.bookingChannelFeatures.airportPickupSettings.canSkip,
        },
      };

      return _.omitBy(booking, _.isNil);
    },
    pickupAddress() {
      return this.pickup.description;
    },
    travelRestrictionErrorType() {
      return _.get(this.travelRestrictionError, 'ruleType', '');
    },
    isPreBookingEnabled() {
      return this.bookingChannelFeatures.isPreBookingEnabled;
    },
  },
  methods: {
    checkBusinessDispatchInvoiceDisabled(paymentMethodType) {
      return paymentMethodType === 'businessDispatchInvoice' && this.isEditMode;
    },
    async getFareEstimation() {
      this.isFareLoading = true;

      const params = {
        body: {
          bookingChannelId: this.bookingChannelId,
          offerId: this.offerId,
          pickupTime: dayjs(this.pickupTime || new Date().getTime()).format('YYYY-MM-DDTHH:mm:ss'),
          origin: {
            address: this.pickup.description,
            latitude: this.pickup.latitude || this.pickup.coordinates.latitude,
            longitude: this.pickup.longitude || this.pickup.coordinates.longitude,
            dispatchAddressId: this.pickup.dispatchAddressId || undefined,
            locationTypes: this.pickup.locationTypes,
          },
          destination: {
            address: this.destination.description,
            latitude: this.destination.latitude || this.destination.coordinates.latitude,
            longitude: this.destination.longitude || this.destination.coordinates.longitude,
            dispatchAddressId: this.destination.dispatchAddressId || undefined,
            locationTypes: this.destination.locationTypes,
          },
          waypoints: [],
        },
        paymentMethodType: this.getPaymentObject(this.paymentMethod).paymentMethodType,
      };

      if (this.waypoints.length > 0) {
        params.body.waypoints = this.waypoints.map(el => ({
          address: el.description,
          latitude: el.latitude || el.coordinates.latitude,
          longitude: el.longitude || el.coordinates.longitude,
          dispatchAddressId: this.destination.dispatchAddressId || undefined,
          locationTypes: this.destination.locationTypes,
        }));
      }

      const isOriginSet = params.body.origin.latitude && params.body.origin.longitude;
      const isDestinationSet = params.body.destination.latitude && params.body.destination.longitude;
      // If origin or destination is not set, do not call fare estimation API
      if (!isOriginSet || !isDestinationSet) {
        // eslint-disable-next-line no-console
        console.log('Origin or destination is not set. Skipping fare estimation');
        this.isFareLoading = false;
        return;
      }

      this.fareEstimation = await this.$store.dispatch('fareEstimation/getFareEstimation', params);

      if (this.fareEstimation) this.isFareLoading = false;
    },
    updateAddresses(type, value, index) {
      if (index !== undefined) {
        this.waypoints[index] = value;
      } else {
        this[type] = value;
      }

      if (type === 'waypoints') {
        this.getFareEstimation();
      }
    },
    addDynamicHeightForTimeline(index) {
      const element = document.querySelector('.v-timeline');
      const newHeight = 63 * index;
      element.style.setProperty('--before-height', `${newHeight}px`);
    },
    addWaypoint() {
      this.waypoints.push({
        address: '',
        latitude: null,
        longitude: null,
        locationTypes: [],
      });
      this.addDynamicHeightForTimeline(this.waypoints.length + 1);
    },
    removeWaypoint(index) {
      this.waypoints.splice(index, 1);
      this.addDynamicHeightForTimeline(this.waypoints.length + 1);

      if (this.waypoints.length) {
        this.$store.commit('geolocation/removeWaypointIndex', index);
      } else {
        this.$store.commit('geolocation/resetWaypoints');
      }
    },
    updatePickupTime(value) {
      this.pickupTime = value;
    },
    updateOffer({ offerId, fleetId }) {
      this.offerId = offerId;
      this.fleetId = fleetId;
    },
    updateUserInformation({ givenName, familyName, phone }) {
      this.userInformation = { givenName, familyName, phone };
    },
    getPaymentObject(item) {
      if (item && item.paymentMethodType === 'cash') {
        return {
          paymentMethodType: item.paymentMethodType,
        };
      }

      if (item && ['creditCard', 'personalCard'].includes(item.paymentMethodType)) {
        return {
          paymentMethodType: item.paymentMethodType,
          id: item.id,
          paymentProviderName: this.paymentProviderName,
        };
      }

      if (item && item.paymentMethodType === 'businessDispatchInvoice') {
        return {
          paymentMethodType: item.paymentMethodType,
          id: item.id,
          businessAccountId: item.businessAccount.id,
        };
      }

      return null;
    },
    async submitBooking() {
      if (
        !this.showAccountFields
        && this.paymentMethod.paymentMethodType === 'businessDispatchInvoice'
        && Object.entries(this.accountFields).length
      ) {
        this.showAccountFields = true;
        return;
      }

      if (this.accountFields.length) {
        this.booking.fields = Object.entries(this.accountFields).reduce(
          // eslint-disable-next-line no-unused-vars
          (acc, [k, obj]) => {
            // eslint-disable-next-line no-param-reassign
            acc[obj.id] = obj.value;
            return acc;
          },
          {}
        );
      }

      if (!this.$v.$invalid && !this.bookingInCreation) {
        if (!this.isShowFlightNumber) {
          delete this.booking.bookingProperties;
        }

        if (this.isEditMode) {
          this.booking.status = this.loadedBooking.status;
          this.booking.bookingId = this.loadedBooking.bookingId;
          const result = await this.$store.dispatch('book/updateBooking', {
            booking: this.booking,
          });

          this.handleCallReturnBooking(result);
        } else {
          const result = await this.$store.dispatch('book/createV2', {
            booking: { ...this.booking, returnBooking: false },
          });

          this.handleCallReturnBooking(result);
        }
      } else {
        this.$v.$touch();
      }
    },
    async handleCallReturnBooking(result) {
      if (result && this.returnBookingTime && this.bookReturnBooking) {
        const returnBookingParams = {
          ...this.booking,
          pickupTime: dayjs(this.returnBookingTime).format('YYYY-MM-DDTHH:mm:ss'),
          origin: {
            address: this.destination.description,
            latitude: this.destination.latitude,
            longitude: this.destination.longitude,
          },
          destination: {
            address: this.pickup.description,
            latitude: this.pickup.latitude,
            longitude: this.pickup.longitude,
          },
          returnBooking: true,
          bookingProperties: !this.returnFlightNumber
            ? undefined
            : {
              flightInfo: {
                number: this.returnFlightNumber,
              },
            },
        };

        await this.$store.dispatch('book/createV2', { booking: returnBookingParams });
      }
    },
    showConfirmation() {
      this.confirmationDialog = true;
    },
    closeConfirmation() {
      this.confirmationDialog = false;
    },
    submitConfirmation() {
      this.closeConfirmation();
      this.submitBooking();
    },
    updateConfirmationFields(fields) {
      this.confirmationFields = fields;
    },
    employeeGroupSelected(employeeGroup) {
      this.employeeGroup = employeeGroup;
    },
    confirmErrorDialog() {
      this.$store.dispatch('book/clearRestrictionError');
    },
    paymentMethodName(val) {
      if (val.paymentMethodType === 'cash') {
        return this.$t('book.form.payment.cash');
      }
      if (val.paymentMethodType === 'new') {
        return this.$t('book.form.payment.new');
      }

      if (val.businessAccount) {
        return this.$t('book.form.payment.businessAccount', { name: val.businessAccount.name });
      }

      return this.$t('book.form.payment.cardEnding', { number: val.last4 });
    },
    async loadPaymentMethods() {
      await this.getPaymentMethods();
      switch (this.userLoginType) {
        case 'businessAccount':
          this.strategy = strategyHelper.business(this.$i18n);
          break;
        case 'ic2Account':
          this.strategy = strategyHelper.ic2Account(this.$i18n);
          break;
        default:
          this.strategy = strategyHelper.passenger(this.$i18n);
          this.strategy.paymentMethodItems.push(...this.data);
          break;
      }

      if (this.loadedBooking) {
        const { payment } = this.loadedBooking;

        if (payment && payment.paymentMethodTypeV2 === 'cash') {
          this.paymentMethod = this.strategy.paymentMethodItems.find(
            el => el.paymentMethodType === payment.paymentMethodTypeV2
          );
        } else if (payment && payment.type === 'creditCard') {
          this.paymentMethod = this.strategy.paymentMethodItems.find(
            el => el.id === payment.paymentMethod.id
          );
        } else if (payment && payment.paymentMethodTypeV2 === 'businessDispatchInvoice') {
          this.paymentMethod = this.strategy.paymentMethodItems.find(
            el => el.id === payment.businessAccountId
          );
        }
      }
      this.paymentMethodType = this.strategy.paymentMethodType;
    },
    async loadBookingsPaymentRequiresAction() {
      await this.$store.dispatch('bookings/listBookingsRequirePaymentAction', {
        filter: ['future'],
        maxPerPage: 10,
        sort: '-pickupTime',
        page: 0,
      });
    },
    closeConfirmDuplicateDialog() {
      this.$store.commit('book/setDuplicateConfirmError', false);
      this.$store.commit('book/setBookingDuplicateInfos', {});
    },
    async confirmDuplicateBooking() {
      await this.$store.dispatch('book/createV2', {
        booking: { ...this.bookingDuplicateInfos, isDuplicateAcknowledged: true },
      });
      this.$store.commit('book/setDuplicateConfirmError', false);
      this.$store.commit('book/setBookingDuplicateInfos', {});
    },
    cancelEdit() {
      if (this.showAccountFields) {
        this.showAccountFields = false;
        return;
      }

      if (this.isEditMode) {
        this.$emit('cancelUpdate');
      } else {
        this.$router.push({
          name: 'bookings',
          params: {
            tab: this.bookingTab,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.payment-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.v-menu__content {
  & .v-list-item__title {
    text-align: left;
  }
}

.cancel-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  cursor: pointer;
  color: #888888;
}
</style>
